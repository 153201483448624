body {
  font-family: 'Inter',  -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Ubuntu, sans-serif;

  margin: 0;
  font-size: 14px;
  line-height: 1.43;
  -moz-osx-font-smoothing: grayscale;
}

body a {
  text-decoration: none;
  color: #838fa1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}
